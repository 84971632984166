<template>
  <div class="error-page">
    <div>
      {{msg}}
    </div>
  </div>
</template>

<script>
export default {
  layout: 'normal',
  data() {
    return {
      msg: 'page not found',
    };
  },
  mounted() {
    const { msg } = this.$route.query;
    if (msg) {
      this.msg = msg;
    }
  },
};
</script>

<style lang="scss" scoped>
.error-page{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}
</style>
